if ('define' in window) {
define("discourse/theme-32/discourse/api-initializers/init-custom-hamburger-links", ["exports", "discourse/lib/api"], function (_exports, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const settings = require("discourse/lib/theme-settings-store").getObjectForTheme(32);
  const themePrefix = key => `theme_translations.32.${key}`;
  var _default = _exports.default = (0, _api.apiInitializer)("0.8.18", api => {
    (settings.Hamburger_links || "").split("|").forEach((link, index) => {
      let [rawLabel, href] = (link || "").split(",");
      api.addCommunitySectionLink(baseSectionLink => {
        return class CustomSectionLink extends baseSectionLink {
          name = (() => `custom-link-${index + 1}`)();
          href = (() => href)();
          route = "";
          text = (() => rawLabel)();
          title = (() => rawLabel)();
        };
      }, false);
    });
  });
});
}
